<template>
    <div class="home">
        <route-list
            @newRoutes="addNewRoute()"
            :key="userComponentKey"
            @deleteRoute="deleteRoute"
        />
        <add
            :edit-mode="editMode"
            :is-active="drawer"
            :user-id="userId"
            @drawerclosed="drawer = false"
            @refreshData="refreshData"
        />
        <routeDetail
            :delete-mode="deleteMode"
            :is-active="deleteDrawer"
            :user-id="userId"
            :routeData="routeData"
            @drawerclosed="deleteDrawer = false"
            @refreshData="refreshData"
        />
    </div>
</template>
<script>
import routeList from "./components/routeList.vue";
import add from "./components/add.vue";
import routeDetail from "./components/routeDetail.vue";

export default {
    name: "Home",
    data() {
        return {
            drawer: false,
            editMode: false,
            userId: null,
            routeId: null,
            userComponentKey: 0,
            userComponentKeyDelete: 0,
            deleteDrawer: false,
            deleteMode: false,
            routeData: {},
        };
    },
    components: {
        routeList,
        add,
        routeDetail,
    },
    methods: {
        addNewRoute() {
            this.editMode = false;
            this.userId = null;
            this.drawer = true;
        },
        deleteRoute(row) {
            this.deleteMode = true;
            this.deleteDrawer = true;
            this.routeData = row;
        },
        refreshData() {
            this.userComponentKey += 1;
            this.drawer = false;
            this.deleteDrawer = false;
        },
    },
    async mounted() {},
};
</script>
