<template>
    <div>
        <div class="w-100">
            <Header title="" />
        </div>

        <div class="w-100 d-flex flex-row flex-wrap">
            <left-side-bar />
            <div class="ml-2" style="width: 90vw">
                <div
                    class="
                        d-flex
                        flex-row flex-wrap
                        justify-between
                        align-items-center
                        w-100
                    "
                >
                    <h1 class="font-xx-large font-weight-bold">Transporte</h1>
                    <el-button
                        class="pull-end bg-yellow font-12 font-weight-bold"
                        style="float: right"
                        round
                        @click="$emit('newRoutes')"
                    >
                        NUEVA RUTA
                    </el-button>
                </div>
                <!-- <div>
                    <tab-vehicle-list
                        @editVehicle="rowClicked"
                        :tab-role="tabRole"
                    />
                </div> -->
                <el-divider></el-divider>
                <h3 style="font-size: 21px; font-weight: bold">Rutas</h3>

                <!-- /// LA BUSQUEDA /// -->
                <el-col :span="8">
                    <div style="width: 350px">
                        <el-input v-model="searchByquery" placeholder="Buscar">
                            <template style="color: white" #append>
                                <el-button
                                    style="background-color: #3f69a6"
                                    @click="searchByQuery('')"
                                    type="primary"
                                >
                                    <i
                                        style="color: white"
                                        class="el-icon-search el-icon-center"
                                    ></i>
                                </el-button>
                            </template>
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                        <el-button
                            size="mini"
                            style="
                                border: none;
                                background-color: transparent;
                                border-color: transparent;
                            "
                        >
                            <i
                                class="material-icons text-secondary"
                                style="font-size: 18px"
                                >filter_alt</i
                            >
                        </el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button
                            @click="searchByQuery('Matutina')"
                            size="mini"
                            style="
                                border: none;
                                background-color: transparent;
                                border-color: transparent;
                                padding: 10px;
                                margin-left: 0px;
                            "
                        >
                            <i
                                class="material-icons text-secondary"
                                style="font-size: 18px"
                                >light_mode</i
                            >
                        </el-button>
                        <el-button
                            @click="searchByQuery('Vespertina')"
                            size="mini"
                            style="
                                border: none;
                                background-color: transparent;
                                border-color: transparent;
                                padding: 10px;
                                margin-left: 0px;
                            "
                        >
                            <i
                                class="material-icons text-secondary"
                                style="font-size: 18px"
                                >nightlight</i
                            >
                        </el-button>
                    </div>
                </el-col>
                <br />
                <br />
                <br />
                <!-- /// LA TABLA /// -->
                <el-table
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    style="width: 100%; max-width: 90vw"
                    @selection-change="handleSelectionChange"
                    @row-click="rowClicked"
                >
                    <!-- <el-table-column type="expand">
                        <template>
                            <p>State:</p>
                            <p>City:</p>
                            <p>Address:</p>
                            <p>Zip:</p>
                        </template>
                    </el-table-column> -->
                    <el-table-column property="id" label="Código" width="200" />
                    <el-table-column
                        property="workSession"
                        label="Turno"
                        width="200"
                    />
                    <el-table-column property="date" label="Fecha" width="200">
                        <template #default="scope">
                            {{
                                moment(scope.row.creationDate).format(
                                    "ddd MMM DD, YYYY"
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        property="neighbourhoods"
                        label="Zonas"
                        width="200"
                    />
                    <el-table-column
                        property="deliveries"
                        label="Deliveries"
                        width="200"
                    />
                    <el-table-column
                        property="orderCount"
                        label="Ordenes"
                        width="200"
                    />
                    <!-- <el-table-column label="Eliminar" width="220">
                        <template #default="scope">
                            <el-button
                                type="default"
                                style="padding: 10px"
                                size="mini"
                                icon="el-icon-delete"
                                @click="handleDeleteVehicle(scope)"
                            ></el-button>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column
                        label="Estatus"
                        column-key="status"
                        width="220"
                    >
                        <template #default="scope">
                            <el-switch
                                v-model="tableData[scope.$index].isActive"
                                active-color="#13ce66"
                                @change="
                                    onStatusChanged(tableData[scope.$index].id)
                                "
                                inactive-color="#ff4949"
                            />
                        </template>
                    </el-table-column> -->
                </el-table>

                <br />
                <br />
                <el-row :gutter="20">
                    <el-col :span="12" :offset="6">
                        <div class="block">
                            <el-pagination
                                layout="prev, pager, next"
                                :page-size="page_size"
                                :page-count="total_pages"
                                :total="total_count"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                            ></el-pagination>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/dashboard/include/Header";
import moment from "moment";
import "moment/locale/es-do";
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import _service from "@/services/routeListService";

export default defineComponent({
    name: "RouteList",
    components: { Header, leftSideBar },

    data() {
        return {
            tabRole: "",
            searchByquery: "",
            drawer: ref(false),
            activeName: "first",
            page_size: 10,
            page_index: 1,
            total_count: 23,
            total_pages: 10,
            role: "Delivery",
            tableData: [
                {
                    id: "",
                    workSession: "",
                    date: "",
                    neighbourhoods: "",
                    deliveries: "",
                    orderCount: "",
                },
            ],
            multipleSelection: [],
            loading: false,
        };
    },
    created() {
        this.getAllRoutes(10, 1);
        this.moment = moment;
    },
    mounted() {
        this.tabRole = "Delivery";
        this.moment.locale("es-do");
    },
    methods: {
        handleSizeChange(val) {
            console.log(`${val} items per page`);
        },
        // onTabChanged(name) {
        //     this.tabRole = name.props.label;
        // },
        handleCurrentChange(val) {
            const data = {
                PageSize: this.page_size,
                PageIndex: val,
                Role: this.role,
            };
            _service.getRoutes(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        rowClicked(row) {
            this.$emit("deleteRoute", row);
            console.log(row);
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        searchByQuery(busqueda) {
            const data = {
                Query: busqueda != "" ? busqueda : this.searchByquery,
                PageSize: this.page_size,
                PageIndex: this.page_index,
                Role: this.role,
            };
            _service.getRoutes(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        getAllRoutes(page_size, page_index) {
            const data = {
                PageSize: page_size,
                PageIndex: page_index,
            };
            this.loading = true;
            _service
                .getRoutes(data)
                .then((data) => {
                    this.total_pages = data.data.totalPages;
                    this.total_count = data.data.pageSize;
                    this.tableData = data.data.items;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
});
</script>
<style scoped>
i.color-icon {
    color: white;
}
</style>
