<template>
    <el-drawer
        v-model="deleteDrawer"
        :direction="rtl"
        @closed="handleClose()"
        @opened="handleOpen()"
    >
        <el-scrollbar height="85vh">
            <el-form
                class="p-4 text-primary"
                label-position="top"
                label-width="100px"
                :model="this.$props.routeData"
                ref="routes"
                :rules="rules"
            >
                <span class="font-larger font-weight-bold">
                    {{ this.$props.routeData.id }}
                </span>
                <div class="mt-10 d-flex flex-column">
                    <span>Turno</span>

                    <i class="mt-3 material-icons">
                        {{
                            this.$props.routeData.workSession == "Matutina"
                                ? "light_mode"
                                : "nightlight"
                        }}
                    </i>
                </div>
                <div class="mt-10 d-flex flex-column">
                    <span>Fecha</span>
                    <span class="mt-3 font-weight-bold">{{
                        this.$props.routeData.date
                    }}</span>
                </div>
                <div class="mt-10 d-flex flex-column">
                    <span>&Oacute;rdenes</span>
                    <span class="mt-3 font-weight-bold">{{
                        this.$props.routeData.orderCount
                    }}</span>
                </div>
                <div class="mt-10 d-flex flex-column">
                    <span>Zonas</span>
                    <div class="d-flex flex-row align-items-center flex-wrap">
                        <span
                            v-for="zone in this.$props.routeData.neighbourhoods"
                            :key="zone.id"
                            class="mt-3 ml-2 font-small"
                        >
                            {{ zone }}
                        </span>
                    </div>
                </div>
                <div class="mt-10 d-flex flex-column">
                    <span>Deliveries</span>
                    <div class="mt-3">
                        <el-input
                            v-model="search_user_list"
                            @keyup="searchUserList"
                            placeholder="Buscar nombre"
                        >
                            <template style="color: white" #append>
                                <el-button
                                    style="background-color: #3f69a6"
                                    @click="searchUserList"
                                    type="primary"
                                >
                                    <i
                                        style="color: white"
                                        class="el-icon-search el-icon-center"
                                    ></i>
                                </el-button>
                            </template>
                        </el-input>
                    </div>
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            flex-wrap
                            mt-3
                            overflow-y-scroll
                        "
                        style="max-heght: 500px"
                    >
                        <table class="table w-100">
                            <thead>
                                <tr>
                                    <th class="font-small">Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="deliverie in deliveriesList"
                                    :key="deliverie"
                                    class="font-medium p-2 font-weight-bold"
                                >
                                    <td>{{ deliverie }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="mt-10 d-flex flex-column">
                    <span>&Oacute;rdenes</span>
                    <div class="mt-3">
                        <el-input
                            v-model="search_order_list"
                            @keyup="searchOrderList"
                            placeholder="Buscar orden"
                        >
                            <template style="color: white" #append>
                                <el-button
                                    style="background-color: #3f69a6"
                                    @click="searchOrderList"
                                    type="primary"
                                >
                                    <i
                                        style="color: white"
                                        class="el-icon-search el-icon-center"
                                    ></i>
                                </el-button>
                            </template>
                        </el-input>
                    </div>
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            flex-wrap
                            mt-3
                            overflow-y-scroll
                        "
                        style="max-heght: 500px"
                    >
                        <table class="table w-100">
                            <thead>
                                <tr>
                                    <th class="font-small">C&oacute;digo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="order in orderList"
                                    :key="order"
                                    class="font-medium p-2 font-weight-bold"
                                >
                                    <td>{{ order }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <el-divider></el-divider>
                <h3 class="font-large font-weight-bold font-italic">
                    Herramientas
                </h3>
                <el-button
                    type="default"
                    style="padding: 10px"
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteRoute"
                >
                </el-button>
            </el-form>
        </el-scrollbar>
    </el-drawer>
    <!-- test -->
</template>

<script>
import { defineComponent } from "vue";
import {
    ElDrawer,
    ElButton,
    ElForm,
    ElScrollbar,
    //ElDatePicker,
    //ElMessage,
} from "element-plus";
import moment from "moment";

export default defineComponent({
    name: "routeDetail",
    components: {
        ElDrawer,
        ElButton,
        ElForm,
        ElScrollbar,
        //ElDatePicker,
    },
    data() {
        return {
            deleteDrawer: false,
            routes: {},
            rules: {},
            deliveriesList: [],
            search_user_list: "",
            orderList: [],
            search_order_list: "",
        };
    },
    props: {
        deleteMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: null,
        },
        routeData: {
            type: Array,
            default: null,
        },
    },
    watch: {
        isActive(value) {
            this.deleteDrawer = value;
        },
    },
    created() {
        this.moment = moment;
    },
    methods: {
        handleClose() {
            this.$emit("drawerclosed");
            this.deleteDrawer = false;
            this.$refs["routes"].resetFields();
        },
        async handleOpen() {
            this.deliveriesList = this.$props.routeData.deliveries;
            this.orderList = this.$props.routeData.orders;
        },
        searchUserList() {
            this.deliveriesList = [];

            this.$props.routeData.deliveries.forEach((d) => {
                let re = new RegExp(this.search_user_list, "gi");
                let search = d.match(re);

                if (search) {
                    this.deliveriesList.push(d);
                }
            });
        },
        searchOrderList() {
            this.orderList = [];

            this.$props.routeData.orders.forEach((o) => {
                let re = new RegExp(this.search_order_list, "gi");
                let search = o.match(re);

                if (search) {
                    this.orderList.push(o);
                }
            });
        },

        async deleteRoute() {
            //let that = this;
            /*
            this.$refs["user"].validate(async (valid) => {
                if (valid) {
                    if (that.deleteMode) {
                        await that
                            .updateUser(this.user)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message: "Usuario actualizado exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    } else {
                        await that
                            .addUser(this.user)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message: "Usuario agregado exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    }
                } else {
                    return false;
                }
            });
            */
        },
    },
    async mounted() {},
});
</script>
<style lang="scss">
.el-form-item__label {
    font-weight: bold !important;
}
.el-icon {
    --font-size: 31px;
}
.table {
    border: 1px solid #e4e7ed;
    border-radius: 5px;
    border-collapse: collapse;
}
.table thead tr th,
.table tbody tr td {
    padding: 10px;
}
.table tbody tr {
    border: 1px solid #e4e7ed;
}
</style>
