<template>
    <el-drawer v-model="drawer" :direction="rtl">
        <el-scrollbar class="ml-3" height="85vh">
            <h2 class="font-xx-large font-weight-bold ml-3">Ruta Nueva</h2>
            <el-form
                class="p-4"
                label-position="top"
                label-width="100px"
                :model="route"
                ref="route"
                :rules="rules"
            >
                <el-form-item label="Fecha" prop="fecha">
                    <el-date-picker
                        class="w-75"
                        v-model="route.date"
                        type="date"
                        size="large"
                        placeholder="Seleccione una fecha"
                    >
                    </el-date-picker>
                </el-form-item>
                <div class="d-flex flex-column mt-5">
                    <h5 class="m-0">Selecciona el turno</h5>
                    <div class="d-flex flex-row align-items-center p-1">
                        <el-button
                            size="mini"
                            style="
                                border: none;
                                border-color: transparent;
                                padding: 10px;
                                margin-left: 0px;
                            "
                            :class="[route.shift_day ? 'bg-primary' : null]"
                            :v-model="route.shift_day"
                            @click="selectShift('day')"
                        >
                            <i
                                class="material-icons"
                                style="font-size: 18px"
                                :class="[
                                    route.shift_day
                                        ? 'text-white'
                                        : 'text-gray-dark',
                                ]"
                                >light_mode</i
                            >
                        </el-button>
                        <el-button
                            size="mini"
                            style="
                                border: none;
                                border-color: transparent;
                                padding: 10px;
                                margin-left: 0px;
                            "
                            :class="[route.shift_night ? 'bg-primary' : null]"
                            :v-model="route.shift_night"
                            @click="selectShift('night')"
                        >
                            <i
                                class="material-icons"
                                style="font-size: 18px"
                                :class="[
                                    route.shift_night
                                        ? 'text-white'
                                        : 'text-gray-dark',
                                ]"
                                >nightlight</i
                            >
                        </el-button>
                    </div>
                </div>

                <div class="w-75 mt-5 d-flex flex-row align-items-center">
                    <el-button @click="$emit('drawerclosed')" round
                        >Cancelar</el-button
                    >
                    <el-button
                        class="pull-end bg-yellow"
                        style="float: right"
                        round
                        @click.prevent="submit()"
                        :disabled="btn_continue_status"
                        >Comenzar Ruta</el-button
                    >
                </div>
            </el-form>
        </el-scrollbar>
    </el-drawer>
    <!-- test -->
</template>

<script>
import { defineComponent } from "vue";
import {
    ElDrawer,
    ElButton,
    ElForm,
    ElScrollbar,
    ElDatePicker,
} from "element-plus";
export default defineComponent({
    components: {
        ElDrawer,
        ElButton,
        ElForm,
        ElScrollbar,
        ElDatePicker,
    },
    data() {
        return {
            drawer: false,
            route: {
                date: null,
                shift_day: 0,
                shift_night: 0,
            },

            btn_continue_status: 1,
        };
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isActive(value) {
            this.drawer = value;
        },
    },
    methods: {
        selectShift(shift) {
            switch (shift) {
                case "day":
                    this.route.shift_day = 1;
                    this.route.shift_night = 0;
                    break;
                case "night":
                    this.route.shift_night = 1;
                    this.route.shift_day = 0;
                    break;
            }

            this.btn_continue_status = 0;
        },
        submit() {
            localStorage.setItem("route_date", this.route.date);
            localStorage.setItem("route_shift_day", this.route.shift_day);
            localStorage.setItem("route_shift_night", this.route.shift_night);

            this.$router.push({
                name: "step1",
            });
        },
    },
    async mounted() {},
});
</script>
<style lang="scss">
.el-form-item__label {
    font-weight: bold !important;
}
.el-icon {
    --font-size: 31px;
}
</style>
